import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Contact Info")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"md":"12"}},[_c(VTextField,{attrs:{"label":"Admin Name","clearable":!_vm.readonly,"single-line":"","readonly":_vm.readonly},model:{value:(_vm.orgData.contactInfo.name),callback:function ($$v) {_vm.$set(_vm.orgData.contactInfo, "name", $$v)},expression:"orgData.contactInfo.name"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VTextField,{attrs:{"label":"Department/Title","clearable":!_vm.readonly,"single-line":"","readonly":_vm.readonly},model:{value:(_vm.orgData.contactInfo.dprtmnt),callback:function ($$v) {_vm.$set(_vm.orgData.contactInfo, "dprtmnt", $$v)},expression:"orgData.contactInfo.dprtmnt"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VTextField,{attrs:{"label":"Phone Number","clearable":!_vm.readonly,"single-line":"","readonly":_vm.readonly},model:{value:(_vm.orgData.contactInfo.phone),callback:function ($$v) {_vm.$set(_vm.orgData.contactInfo, "phone", $$v)},expression:"orgData.contactInfo.phone"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VTextField,{attrs:{"label":"Email Address","clearable":!_vm.readonly,"single-line":"","readonly":_vm.readonly},model:{value:(_vm.orgData.contactInfo.email),callback:function ($$v) {_vm.$set(_vm.orgData.contactInfo, "email", $$v)},expression:"orgData.contactInfo.email"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }