import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-2 indigo white--text",attrs:{"dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Telehealth")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"md":"12"}},[_c(VTextarea,{attrs:{"label":"Email Subject","clearable":!_vm.readonly,"single-line":"","auto-grow":"","rows":"1","readonly":_vm.readonly},model:{value:(_vm.orgData.telehealth.emailSubject),callback:function ($$v) {_vm.$set(_vm.orgData.telehealth, "emailSubject", $$v)},expression:"orgData.telehealth.emailSubject"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VTextarea,{attrs:{"label":"Email From Account","clearable":!_vm.readonly,"single-line":"","auto-grow":"","rows":"1","readonly":_vm.readonly},model:{value:(_vm.orgData.telehealth.emailFromAccount),callback:function ($$v) {_vm.$set(_vm.orgData.telehealth, "emailFromAccount", $$v)},expression:"orgData.telehealth.emailFromAccount"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VTextarea,{attrs:{"label":"Email Body","clearable":!_vm.readonly,"single-line":"","auto-grow":"","rows":"1","readonly":_vm.readonly},model:{value:(_vm.orgData.telehealth.emailBody),callback:function ($$v) {_vm.$set(_vm.orgData.telehealth, "emailBody", $$v)},expression:"orgData.telehealth.emailBody"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }